import React, {useRef} from "react";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import Ss1 from "../assets/images/ss1.png";
import Ss2 from "../assets/images/ss2.png";
import Ss3 from "../assets/images/ss3.png";
import OwlArrow from "../assets/images/sliderArrowBlack.png";
import RestakeSliderCard from "./RestakeSliderCard";
// import MutualCard from "../assets/images/Mutual Incentive card.svg";
import NewBtn from "./NewBtn";

//const $ = $;
const Particle = () => {
    const slides = [
        {
            title: "Mutual Incentive",
            text: "Rewards are earned for off-chain compute, data reporting, and consensus participation. This system " +
                "cultivates a symbiotic relationship based on aligned incentives, bridging the gap between existing " +
                "validators and smart contract applications, ensuring that value is maintained within the on-chain " +
                "ecosystem.",
            image: Ss1,
        },
        {
            title: "Capital Efficiency",
            text: "Joining as an Ethereum validator is a seamless process, extending the use of already deposited stake. " +
                "Validators can leverage their existing node infrastructure, requiring only modest additions for Eoracle " +
                "software.",
            image: Ss2,
        },
        {
            title: "Native Security",
            text: "Replace traditional and centralized security with the assurance of the world's largest and most " +
                "diversely trusted validator pool. Enhance the ecosystem by extending Ethereum’s PoS stability, " +
                "scalability and trust to connect smart contracts with real-world data.",
            image: Ss3,
        },
    ];

    const options = {
        margin: 0,
        loop: true,
        autoplay: true,
        autoplayTimeout: 4000,
        speed: 700,
        autoplaySpeed: 500,
        nav: false,
        autoWidth: true,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 2
            },
            768: {
                items: 3
            }
        }
    };

    const handlePrevClick = () => {
        // Manually trigger the Owl Carousel to move to the previous item
        if (carouselRef.current) {
            carouselRef.current.prev(700);
        }
    };

    const handleNextClick = () => {
        // Manually trigger the Owl Carousel to move to the next item
        if (carouselRef.current) {
            carouselRef.current.next(700);
        }
    };
    
    const handleMouseLeave = () => {
        setTimeout(() => {
            handleNextClick();
        }, 1500)
    }

    const carouselRef = useRef();

    return (
        <div className="particle_div" id={"whyRestake"}>
            <div className="middle_section">
                <div className="inner_wrapper">
                    <div className="headerWrap">
                        <h5>Why Restake?</h5>
                        <div id="MyNavs" className={"custom-navigation"}>
                            <button onClick={handlePrevClick}><img src={OwlArrow} alt=""/></button>
                            <button onClick={handleNextClick}><img src={OwlArrow} alt=""/></button>
                        </div>
                    </div>
                    <div className="access_slider" onMouseLeave={handleMouseLeave}>
                        <OwlCarousel options={options} center ref={carouselRef}>
                            {slides.map((slide, index) => (
                                <RestakeSliderCard key={index} slide={slide}/>
                            ))}
                        </OwlCarousel>
                        {/*<img src={MutualCard} alt="" className={"slide"}/>*/}
                    </div>
                    <NewBtn title={"Start validating"} link={"#"}/>
                </div>
            </div>
        </div>
    );
};

export default Particle;
