import Lottie from "react-lottie";
import btnArrow from "../assets/json/btnArrow.json";
import React, {useState} from "react";
import ArrowStatic from "../assets/images/sliderArrowBlack.png"

const NewBtn = ({title, link, isBtn = false, isMobile = false}) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    if (isBtn) return (
        <button type={"submit"}
            className={`newBtn ${isMobile ? "mobile" : ""}`}
           onMouseEnter={handleMouseEnter}
           onMouseLeave={handleMouseLeave}
        >
            {title}
            {
                isHovered ?
                    <Lottie
                        options={{
                            animationData: btnArrow,
                            className: "btnMotionArrow",
                            autoPlay: false,
                        }}
                    /> :
                    <span className="staticArrow">
                        <img src={ArrowStatic} alt=""/>
                    </span>
            }
        </button>
    )

    return (
        <a target={"_blank"}
           href={link}
           className={`newBtn ${isMobile ? "mobile" : ""}`}
           onMouseEnter={handleMouseEnter}
           onMouseLeave={handleMouseLeave}
        >
            {title}
            {
                isHovered ?
                    <Lottie
                        options={{
                            animationData: btnArrow,
                            className: "btnMotionArrow",
                            autoPlay: false,
                        }}
                    /> :
                    <span className="staticArrow">
                        <img src={ArrowStatic} alt=""/>
                    </span>
            }
        </a>
    )
}

export default NewBtn;
