import React, {useState, useEffect, useRef} from "react";
import Counter from "./Counter";
// import Arrow from "../assets/images/arrow.png";
import Arrow from "../assets/images/scroll btn.svg";
import videoimg from "../assets/images/videoimg.jpg";
import Video from "../assets/video/globe_vid_main_screen.mp4";
import '../css/banner.css'
// import * as TypeB01 from "../assets/json/Type B 01.json";
import * as MainText from "../assets/json/Type B.json";
import * as MainTextMobile from "../assets/json/Type C2.json";
import Lottie from "react-lottie";

const Banner = () => {
  const phrases = [
    "Standard",
    "Trust",
    "Decentralization",
    "Permissionless",
    "Transparency",
    "Resilience",
    "Programmability",
    "Cryptoeconomics",
  ];


  const [animationDivActive, setAnimationDivActive] = useState(false);
  const [videoActive, setVideoActive] = useState(false);
  const [animationDivRemove, setAnimationDivRemove] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    let intervalCols;

    const timeoutvideo = setTimeout(() => {
      setVideoActive(true);
    }, 500);

    return () => {
      clearInterval(intervalCols);
      //clearInterval(intervalColsInitial);
      clearTimeout(timeoutvideo);
    };
  }, []);

  const handleVideoLoadedData = () => {
    const timeoutAnimationDiv = setTimeout(() => {
      setAnimationDivActive(true);
    }, 300);
    const timeoutremove = setTimeout(() => {
      setAnimationDivRemove(true);
    }, 6500);


  };

  const handleLearnMoreClick = () => {
    const targetElement = document.querySelector(".connect_blockchain");
    console.log(targetElement.offsetTop)

    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop || window.innerHeight,
        behavior: "smooth",
      });
    }
  };

  return (
      <div className="banner">
        <div
            className={`learnmore mobile`}
            onClick={handleLearnMoreClick}
        >
          <img src={Arrow} alt="Arrow"/>
        </div>
        <video
            ref={videoRef}
            autoPlay
            muted
            playsInline
            controls={false}
            preload="metadata"
            onLoadedData={handleVideoLoadedData}
            className={`img_banner ${videoActive ? "active" : ""} ${
                animationDivRemove ? "animationdone" : ""
            }  `}
            // poster={videoimg}
        >
          <source src={Video} type="video/mp4"/>
        </video>
        <div className={`textslider ${animationDivActive ? "active" : ""}`}>
          <Lottie options={{
            loop: true,
            autoplay: true,
            animationData: MainText,
          }}/>
        </div>

        <div className={`mobile textslider ${animationDivActive ? "active" : ""}`}>
          <Lottie options={{
            loop: true,
            autoplay: true,
            animationData: MainTextMobile,
          }}/>
        </div>

        {/*<div*/}
        {/*    className={`count_blog active`}*/}
        {/*>*/}
        <div
            className={`count_blog ${animationDivActive ? "active" : ""} ${
                animationDivRemove ? "animationdone" : ""
            } `}
        >
          <h4>Ethereum Proof of Stake current state</h4>
          <div className="wrap d-flex">
            <div className="col">
              <h3>
              <span>
                <Counter value={881834} duration={2100} delay={2500}/>
              </span>
              </h3>
              <p>
                TOTAL ETHERIUM <br/> VALIDATORS
              </p>
            </div>
            <div className="col">
              <h3>
              <span>
                <Counter value={28166134} duration={2100} delay={2500}/>
              </span>
              </h3>
              <p>
                TOTAL ETHERIUM <br/> STAKED
              </p>
            </div>
            <div className="col">
              <h3>
                $
                <span>
                <Counter value={65000000000} duration={2100} delay={2500}/>
              </span>
              </h3>
              <p>
                TOTAL VALUE <br/> STAKED
              </p>
            </div>
          </div>
          <div className="animationdiv">
            <span/>
          </div>
        </div>
        <div
            className={`learnmore ${animationDivRemove ? "animationdone" : ""} `}
            onClick={handleLearnMoreClick}
        >
        <span>
          <img src={Arrow} alt="Arrow"/>
        </span>
        </div>
      </div>
  );
};

export default Banner;
