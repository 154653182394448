import linkicon from "../assets/images/linkicon.png";
import React, {useEffect, useRef, useState} from "react";
import MovingComponent from "react-moving-text";

const RestakeSliderCard = ({slide}) => {
    const fadeTextRef = useRef();
    const [showSlide, setShowSlide] = useState(false);
    // console.log(fadeTextRef.current);
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            // console.log(entry)
            if (entry.isIntersecting) {
                setShowSlide(true);
                observer.unobserve(entry.target);
            }
        });
    }, { threshold: 0.5 });

    useEffect(() => {
        observer.observe(fadeTextRef.current);
    }, []);
    return (
        <div className="slide" ref={fadeTextRef}>
            <div className="title">
                <h4>{slide.title}</h4>
                <a target={"_blank"} href="https://app.gitbook.com/o/ScGmN7KQt0fgc3h1V55E/s/QvGCmsU8FIcbArIF7C8N/how-eoracle-works" className="link">
                    <img src={linkicon}/>
                </a>
                <div className="img_col">
                    <img src={slide.image}/>
                </div>
            </div>
            <div className="text">
                <div className="img_col">
                    <img src={slide.image}/>
                </div>
                <div className="textWrap">
                    <p>
                        {slide.text}
                    </p>
                    {/*{*/}
                    {/*    showSlide ? <MovingComponent*/}
                    {/*        type="fadeInFromTop"*/}
                    {/*        duration="2000ms"*/}
                    {/*        delay="0s"*/}
                    {/*        direction="normal"*/}
                    {/*        timing="ease"*/}
                    {/*        iteration="1"*/}
                    {/*        fillMode="none"*/}
                    {/*    >*/}
                    {/*        <p>*/}
                    {/*            {slide.text}*/}
                    {/*        </p>*/}
                    {/*    </MovingComponent> : null*/}
                    {/*}*/}
                </div>
                <a target={"_blank"} href="https://app.gitbook.com/o/ScGmN7KQt0fgc3h1V55E/s/QvGCmsU8FIcbArIF7C8N/how-eoracle-works" className="link">
                    <img src={linkicon}/>
                </a>
            </div>
        </div>
    )
}

export default RestakeSliderCard;
