import React from "react";
import ArrowIcon from "../assets/images/morearrow.png";
import Lottie from "react-lottie";
import * as ButtonBG from "../assets/buttons/BTN_D (generic).json"
import btnArrow from "../assets/json/btnArrow.json";
import NewBtn from "./NewBtn";

const Subscribe = () => {
  return (
    <div className="subscribe_wrapper">
      <div className="subscribe_inner">
        <h4>
            Get the latest Eoracle content <br /> straight to your inbox
        </h4>
        <form action="">
          <div className="from_group">
            <input
                type="email"
                placeholder="Enter your email here"
                className="in_field"
            />
            <NewBtn title={"Subscribe now"} isBtn={true}/>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Subscribe;
