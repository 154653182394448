import React, {useEffect, useRef, useState} from 'react';
import restaker_graph from '../assets/images/restaker_graph.svg';
import MovingComponent from "react-moving-text";
import NewBtn from "./NewBtn";

const ValidatorBanner = () => {
    const fadeTextRef = useRef();
    const [showSlide, setShowSlide] = useState(false);
    const [showSlide2, setShowSlide2] = useState(false);
    // const [showSlide3, setShowSlide3] = useState(false);
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setShowSlide(true);
                setTimeout(() => setShowSlide2(true), 1500)
                // setTimeout(() => setShowSlide3(true), 3000)
                observer.unobserve(entry.target);
            }
        });
    }, { threshold: 0.5 });

    useEffect(() => {
        if (fadeTextRef.current) {
            observer.observe(fadeTextRef.current);
        }
    }, [fadeTextRef]);
  return (
      <div className="validator_banner" ref={fadeTextRef}>
          {/*<h1>Restake your ETH, Earn Rewards <br />*/}
          {/*and Democratize Access to Data on Chain</h1>*/}
          <div className="animatedTexts">
              <p className="heading">
                  Restake, Connect, <span>Earn.</span>
              </p>
              {
                  showSlide ? <MovingComponent
                      type="fadeInFromTop"
                      duration="2000ms"
                      delay="0s"
                      direction="normal"
                      timing="ease"
                      iteration="1"
                      fillMode="none"
                  >
                      <p>
                          By becoming an Eoracle validator, you contribute to making smart contracts fully interoperable
                          with real-world data. Participants report, validate, and compute off-chain data, producing
                          verifiable utility for the on-chain ecosystem.
                      </p>
                  </MovingComponent> : null
              }
              {
                  showSlide2 ? <MovingComponent
                      type="fadeInFromTop"
                      duration="2000ms"
                      delay="0s"
                      direction="normal"
                      timing="ease"
                      iteration="1"
                      fillMode="none"
                  >
                      <p className={"secondP"}>
                          Join Eoracle and empower the next era of innovation by applying Ethereum's
                          programmability, security, and trust to the real world.
                      </p>
                  </MovingComponent> : null
              }
          </div>


          {/*{*/}
          {/*    showSlide3 ? <MovingComponent*/}
          {/*        type="fadeInFromTop"*/}
          {/*        duration="2000ms"*/}
          {/*        delay="0s"*/}
          {/*        direction="normal"*/}
          {/*        timing="ease"*/}
          {/*        iteration="1"*/}
          {/*        fillMode="none"*/}
          {/*    >*/}
          {/*        <NewBtn title={"Start validating"} link={"#"}/>*/}
          {/*    </MovingComponent> : null*/}
          {/*}*/}
          <NewBtn title={"Start validating"} link={"#"}/>
          <div className='restaker_graph'>
            <img src={restaker_graph}/>
          </div>
          <NewBtn title={"Start validating"} link={"#"} isMobile={true}/>
      </div>
  );
};

export default ValidatorBanner;
