import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const [headerActive, setHeaderActive] = useState(false);
  const [headerIsactive, setHeaderIsactive] = useState(false);

  const [isMenuOpen, setMenuOpen] = useState(false);
  const handleMenuToggle = () => {
    setMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setHeaderActive(true);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, []);
  useEffect(() => {
    let prevScrollPos = window.pageYOffset;

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingDown = currentScrollPos > prevScrollPos;

      setHeaderIsactive(isScrollingDown);

      prevScrollPos = currentScrollPos;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`d-flex ${headerActive ? 'active' : ''} ${headerIsactive ? 'isactive' : ''}`}>
      <Link to="/" className={`brand ${location.pathname === '/' ? 'active' : ''}`}> Eoracle </Link>
      <div className={`right_col d-flex ${isMenuOpen ? 'active_menu' : ''}`}>
        <ul className='d-flex'>
          <li><Link to="/" className={` ${location.pathname === '/' ? 'active' : ''}`}>Home</Link></li>
          <li><Link to="/validators" className={` ${location.pathname === '/validators' ? 'active' : ''}`}>Validators</Link></li>
          {/*<li><Link to="/data" className={` ${location.pathname === '/data' ? 'active' : ''}`}>Data/ Dapps</Link></li>*/}
          <li><a target={"_blank"} href="https://app.gitbook.com/o/ScGmN7KQt0fgc3h1V55E/s/QvGCmsU8FIcbArIF7C8N/eoracles-aegis-blockchain">Docs</a></li>
        </ul>
        <a href='#' className='wallet'>Connect Wallet</a>
      </div>
      <a href="#" className="toggle-mnu hidden-lg mobile_toggle" data-class={`${isMenuOpen ? 'on' : ''}`} onClick={handleMenuToggle}><span></span></a>
    </header>
  );
};

export default Header;
