import React, {useEffect, useState} from "react";
import Banner from "../components/Banner";
import Connect from "../components/Connect";
import Particle from "../components/Particle";
import Onchain from "../components/Onchain";
import Subscribe from "../components/Subscribe";
import Footer from "../components/Footer";
// import Video from "../assets/video/BACK.mp4";
// import NewVideoBG from "../assets/video/new bg vid/bg.mp4";
// import NewVideoBG from "../assets/video/new bg vid/bg2.mp4";
// import NewVideoBG from "../assets/video/new bg vid/bg111.mov";
import NewVideoBG from "../assets/video/new bg vid/88888.mp4";
import {scrollToAnchor} from "../helpers/ScrollToAnchor";


const Home = () => {

    useEffect(() => {
        const { hash } = window.location;

        if (hash) {
            // Remove the leading '#' from the hash
            const anchorId = hash.substring(1);
            scrollToAnchor(anchorId);
        }
    }, []);
  return (
      <div className="main">
          <div className="scroll-snap">
              <Banner/>
          </div>
          <div className="video_bg">
              <div className="video-wrap">
                  <video
                      src={NewVideoBG}
                      autoPlay
                      muted
                      loop
                      playsInline
                      controls={false}
                  />
              </div>
              <div className="scroll-snap">
                  <Connect/>
              </div>
              <div className="scroll-snap">
                  <Particle/>
              </div>
          </div>
          <div className="scroll-snap">
              <Onchain/>
              <Subscribe/>
              <Footer/>
          </div>
      </div>
  );
};

export default Home;
