import React, {useEffect, useRef, useState} from 'react';
import ArrowButton from '../assets/images/arrow_button.svg';
import SvgBottom from '../assets/images/svg_bottom.svg';
import Lottie from "react-lottie";
import * as LearnMoreBtn from "../assets/buttons/BTN_B.json";
import Video from "../assets/video/BACK.mp4";
import btnArrow from "../assets/json/btnArrow.json";
import NewBtn from "./NewBtn";
import MovingComponent from "react-moving-text";

const NetworkOverview = () => {
    const fadeTextRef = useRef();
    const [showSlide, setShowSlide] = useState(false);
    const [showSlide2, setShowSlide2] = useState(false);
    const [showSlide3, setShowSlide3] = useState(false);
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setShowSlide(true);
                setTimeout(() => setShowSlide2(true), 1500);
                setTimeout(() => setShowSlide3(true), 3000);
                observer.unobserve(entry.target);
            }
        });
    }, { threshold: 0.2 });

    useEffect(() => {
        if (fadeTextRef.current) {
            observer.observe(fadeTextRef.current);
        }
    }, [fadeTextRef]);
  return (
      <div className="main video_bg">
          <div className='network_wrapper' ref={fadeTextRef}>
              <div className="video-wrap">
                  <video
                      src={Video}
                      autoPlay
                      muted
                      loop
                      playsInline
                      controls={false}
                  />
              </div>
              <div className="animatedTexts">
                  {
                      showSlide ? <MovingComponent
                          type="fadeInFromTop"
                          duration="2000ms"
                          delay="0s"
                          direction="normal"
                          timing="ease"
                          iteration="1"
                          fillMode="none"
                      >
                          <h3>
                              Maximize your Capital Efficiency with
                              <br/>
                              Minimal Incremental Cost
                          </h3>
                      </MovingComponent> : null
                  }
                  {
                      showSlide2 ? <MovingComponent
                          type="fadeInFromTop"
                          duration="2000ms"
                          delay="0s"
                          direction="normal"
                          timing="ease"
                          iteration="1"
                          fillMode="none"
                      >
                          <p>
                              Take part in leveraging ETH's cryptoeconomic trust, in exchange for compensation and
                              rewards. As an Eoracle validator you will operate validation services that are designed
                              to be complimentary to your existing Ethereum validation operations.
                          </p>
                      </MovingComponent> : null
                  }
                  {
                      showSlide3 ? <MovingComponent
                          type="fadeInFromTop"
                          duration="2000ms"
                          delay="0s"
                          direction="normal"
                          timing="ease"
                          iteration="1"
                          fillMode="none"
                      >
                          <h2>
                              Network Overview
                          </h2>
                      </MovingComponent> : null
                  }
              </div>
              {/*<h2>*/}
              {/*    Network Overview*/}
              {/*</h2>*/}

          </div>
          <div className='contain_img'>
              <NewBtn title={"Join our network"} link={"https://app.gitbook.com/o/ScGmN7KQt0fgc3h1V55E/s/QvGCmsU8FIcbArIF7C8N/"}/>
              <img src={SvgBottom} alt=""/>
          </div>
          <NewBtn title={"Join our network"} link={"https://app.gitbook.com/o/ScGmN7KQt0fgc3h1V55E/s/QvGCmsU8FIcbArIF7C8N/"} isMobile={true}/>
      </div>
  );
};

export default NetworkOverview;
