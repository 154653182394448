import React, {useState, useRef, useEffect} from "react";
import Lottie from "react-lottie";
import PlatformVideo from "../assets/video/video3.3.mp4";
import MobileImage from "../assets/images/2blImgMobile.png";
import Nextimg from "../assets/images/next.png";
import Previmg from "../assets/images/next.png";
import A_bck from "../assets/json/A_bck.json";
import A_fwd from "../assets/json/A_fwd.json";
import B_bck from "../assets/json/B_bck.json";
import B_fwd from "../assets/json/B_fwd.json";
import C_bck from "../assets/json/C_bck.json";
import C_fwd from "../assets/json/C_fwd.json";
import D_bck from "../assets/json/D_bck.json";
import D_fwd from "../assets/json/D_fwd.json";
import text from "../assets/images/section2text.svg";
import * as Type01 from "../assets/json/Type 01.json";
import * as animationRhombus from "../assets/secondScreenLottie/Asset A (1).json";
import * as LearnMoreBtn from "../assets/buttons/BTN_B.json";
import '../css/connect.css'
import Morearrow from "../assets/images/morearrow.png";
import MovingComponent from "react-moving-text";
import btnArrow from "../assets/json/btnArrow.json";
import NewBtn from "./NewBtn";


let i;
let k = 0;
let animationData;
const Connect = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showSlide, setShowSlide] = useState(false);
  const [showSlide2, setShowSlide2] = useState(false);
  // const [animationData, setAnimationData] = useState(A_fwd);
  const lottieRef = useRef(null);
  // const fadeTextRef = useRef(null);
  const btnFadeRef = useRef(null);

  const slides = [
    { backward: A_bck, forward: D_fwd },
    { backward: B_bck, forward: C_fwd },
    { backward: C_bck, forward: B_fwd },
    { backward: D_bck, forward: A_fwd },
  ];

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Type01,
  };

  const defaultStyles = {
   marginBottom: "24px"
  }

  const rhombusOptions =  {
    loop: true,
    autoplay: true,
    animationData: animationRhombus,
  };

  const rhombusStyle = {
    // width: "100%",
    height: "80vh",
  }
  const nextSlide = () => {
    setCurrentSlide(
      (prevSlide) => (prevSlide + 1 + slides.length) % slides.length
    );
    lottieRef.current.play();
    i = slides[currentSlide].backward;
    k++;
  };

  const prevSlide = () => {
    setCurrentSlide(
      (prevSlide) => (prevSlide - 1 + slides.length) % slides.length
    );
    lottieRef.current.play();
    i = slides[currentSlide].forward;
    k--;
  };
  // console.log("currentSlide==" + k);
  // console.log("currentSlideii==" + i);
  if (k == 0) {
    animationData = slides[3].forward;
  } else {
    animationData = i;
  }

  useEffect(() => {
    const observer2 = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            setShowSlide(true);
            setTimeout(() => setShowSlide2(true), 2500)
            observer2.unobserve(entry.target);
          }, 200)
        }
      });
    }, { threshold: 0.5 });
    if (btnFadeRef.current) {
      observer2.observe(btnFadeRef.current);
    }
  }, []);

  return (
    <div className="connect_blockchain">
      {/*<div className="video-wrap one">*/}
      {/*  <video*/}
      {/*      src={Video}*/}
      {/*      autoPlay={true}*/}
      {/*      muted={true}*/}
      {/*      loop={true}*/}
      {/*  />*/}
      {/*</div>*/}
      <div className="d-flex">
        <div className="left_text" ref={btnFadeRef}>
          <Lottie options={defaultOptions} style={defaultStyles}/>
          {
            showSlide ? <MovingComponent
                type="fadeInFromTop"
                duration="3000ms"
                delay="0s"
                direction="normal"
                timing="ease"
                iteration="1"
                fillMode="none"
            >
              {/*<p className="details">*/}
              {/*  Eoracle equips developers and consumers with the same type <br/> of*/}
              {/*  <span className="withBg">programmability</span>*/}
              {/*  and*/}
              {/*  <span className="withBg">security guarantees</span> <br/>*/}
              {/*  they enjoy with native*/}
              {/*  <span className="bi"><span> smart contracts </span></span>*/}
              {/*  but with an added dimension:*/}
              {/*  <span> a direct, seamless connection to <br/> the real world.</span>*/}
              {/*</p>*/}
              <img src={text}  className={"text"} alt="Eoracle equips developers and consumers with the same type of programmability and security guarantees they enjoy with native smart contracts but with an added dimension: a direct, seamless connection to the real world."/>
            </MovingComponent> : null
          }


          {
            showSlide2 ? <MovingComponent
                type="fadeInFromTop"
                duration="2000ms"
                delay="0s"
                direction="normal"
                timing="ease-in"
                iteration="1"
                fillMode="none"
            >
              <NewBtn title={"Learn more"} link={"https://app.gitbook.com/o/ScGmN7KQt0fgc3h1V55E/s/QvGCmsU8FIcbArIF7C8N/"}/>
            </MovingComponent> : null
          }
          {/*<a href="#" className={"lottieBtn"}>*/}
          {/*  <Lottie*/}
          {/*      options={{*/}
          {/*        animationData: LearnMoreBtn*/}
          {/*      }}*/}
          {/*      style={{*/}
          {/*      width: "260px"*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</a>*/}
          {/*<div className="textslider_colin">*/}
          {/*  /!* <div*/}
          {/*    className="col"*/}
          {/*    style={{ opacity: 1, transition: "opacity 1s" }}*/}
          {/*  >*/}
          {/*    <Lottie*/}
          {/*      ref={lottieRef}*/}
          {/*      options={{*/}
          {/*        loop: false,*/}
          {/*        autoplay: false,*/}
          {/*        animationData: animationData,*/}
          {/*        rendererSettings: {*/}
          {/*          preserveAspectRatio: "xMidYMid slice",*/}
          {/*        },*/}
          {/*      }}*/}
          {/*      height={268}*/}
          {/*      width={400}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  /!* <div className="next_prev">*/}
          {/*    <button onClick={prevSlide}><img src={Previmg} alt="Previous" /></button>*/}
          {/*    <button onClick={nextSlide}><img src={Nextimg} alt="Next" /></button>*/}
          {/*  </div> *!/*/}
          {/*</div>*/}
        </div>
        <div className="right_video">
          <Lottie options={rhombusOptions} style={rhombusStyle}/>
          {/*<video*/}
          {/*  autoPlay*/}
          {/*  loop*/}
          {/*  muted*/}
          {/*  tabIndex="0"*/}
          {/*  autobuffer="autobuffer"*/}
          {/*  preload="preload"*/}
          {/*>*/}
          {/*  <source src={PlatformVideo} type="video/mp4" />*/}
          {/*</video>*/}
          {/*<img src={MobileImage} alt="" className="mobile"/>*/}
        </div>
      </div>
    </div>
  );
};

export default Connect;
