import React, {useEffect, useRef, useState} from "react";

import ChainVideo from "../assets/video/video3.mp4";
import Morearrow from "../assets/images/morearrow.png";
import Lottie from "react-lottie";
import * as LearnMoreBtn from "../assets/buttons/BTN_B.json";
import MovingComponent from "react-moving-text";
import For from "../assets/images/for.svg";
import NewBtn from "./NewBtn";

const Onchain = () => {
  const fadeTextRef = useRef();
  const [showSlide, setShowSlide] = useState(false);
  const [showSlide2, setShowSlide2] = useState(false);
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setShowSlide(true);
        setTimeout(() => {
          setShowSlide2(true);
        }, 1500)
        observer.unobserve(entry.target);
      }
    });
  }, { threshold: 0.5 });

  useEffect(() => {
    if (fadeTextRef.current) {
      observer.observe(fadeTextRef.current);
    }
  }, [fadeTextRef]);
  return (
    <div className="inner_wrapper">
      <div className="onchain_container">
        <div className="textWrap" ref={fadeTextRef}>
          {
            showSlide ? <MovingComponent
                type="fadeInFromTop"
                duration="2000ms"
                delay="0s"
                direction="normal"
                timing="ease"
                iteration="1"
                fillMode="none"
            >
              <h4>
                Decentralized <em>Infrastructure</em>
              </h4>
              <img src={For} alt="" className={"for"}/>
              <h4>
                Decentralized <em>Networks</em>
              </h4>
            </MovingComponent> : null
          }

        </div>

        <video
            autoPlay
            loop
            muted
            playsInline
            controls={false}
            tabIndex="0"
            autobuffer="autobuffer"
            preload="preload"
        >
          <source src={ChainVideo} type="video/mp4"/>
        </video>
        {/*<div ref={fadeTextRef2} className={"textWrap second"}>*/}
        {/*  {*/}
        {/*    showSlide2 ? <MovingComponent*/}
        {/*        type="fadeInFromTop"*/}
        {/*        duration="2000ms"*/}
        {/*        delay="0s"*/}
        {/*        direction="normal"*/}
        {/*        timing="ease"*/}
        {/*        iteration="1"*/}
        {/*        fillMode="none"*/}
        {/*    >*/}
        {/*      <h4>*/}
        {/*        Modular blockchain operates independently*/}
        {/*        <br/>*/}
        {/*        of ethereum’s block time constraints*/}
        {/*      </h4>*/}
        {/*    </MovingComponent> : null*/}
        {/*  }*/}

        {/*</div>*/}

        {/*<a href="#" className="learn_more">*/}
        {/*  Learn more <img src={Morearrow}/>*/}
        {/*</a>*/}
        <NewBtn title={"Learn more"} link={"https://app.gitbook.com/o/ScGmN7KQt0fgc3h1V55E/s/QvGCmsU8FIcbArIF7C8N/"}/>
      </div>
    </div>
  );
};
export default Onchain;
