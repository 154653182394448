import React, {useEffect} from "react";
import { BrowserRouter as Router, Routes ,Route} from 'react-router-dom';
import Home from './pages/Home';
import Validators from './pages/Validators';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';
import './assets/fonts/stylesheet.css';
import CarouselTest from "./components/CarouselTest";
import DataDapps from "./pages/DataDapps";

function App() {
  return (
    <Router>
      <div className="App">
       <Header></Header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/validators" element={<Validators />} />
          <Route path="/data" element={<DataDapps />} />
          <Route path="/test-carousel" element={<CarouselTest />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
